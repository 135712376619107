
(function(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.burger-icon').on('click', function (ev) {
        ev.preventDefault();
        $(this).toggleClass('active');
        $('.menu').toggleClass('active');
    })

    $(window).on('scroll load', function () {
        var offset = $(window).scrollTop();

        if(offset > 50){
            $('.menu-header').addClass('fixed');
        }
        else {
            $('.menu-header').removeClass('fixed');
        }

    }).scroll();

    $('.menu-header .icon-search').on('click', function (e) {
        e.preventDefault();
        $('.menu-header__search').toggleClass('active');
    })

    $('.menu-header__search .close-search').on('click', function (e) {
        e.preventDefault();
        $('.menu-header__search').removeClass('active');
    })

    $(window).on('load', function () {
        if ( window.location.hash ) {
            $('.menu-header').addClass('scroll');
            setTimeout(function () {
                var load_hash = location.hash;
                if (load_hash) {
                    $('html, body').animate({
                        scrollTop: $(load_hash).offset().top - $('.menu-header').outerHeight() - 60
                    }, 1500);
                }
            }, 10)
        };
    })

    $('.expand').on('click', function () {
        $(this).addClass('active');
    })

    $('.toggle').on('click', function (e) {
        e.preventDefault();
        var target = $(this).attr('data-toggle');
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(target).removeClass('active')
            if($(this).find('input[type="checkbox"]')){
                $(this).find('input[type="checkbox"]').prop('checked', false);
            }
        }
        else{
            $(this).addClass('active');
            $(target).addClass('active')
            if($(this).find('input[type="checkbox"]')){
                $(this).find('input[type="checkbox"]').prop('checked', true);
            }
        }
    })
})();

// Text Accordion
(function(){
    $(".accordion-item").each(function(i, item) {
        $(item).find('.accordion-item-content').css('height', 0);
        $(item).removeClass('not-initialized');

        $(item).on("click", function () {
            var item = $(this);
            var contentWrapper = item.find('.accordion-item-content');
            var contentText = item.find('.accordion-item-text');

            item.toggleClass('active');
            var newHeight = contentText.outerHeight();

            if (item.hasClass('active')) {
                contentWrapper.css('height', 0);

                setTimeout(function() {
                    contentWrapper.css('height', newHeight + 'px');
                }, 0);

                setTimeout(function() {
                    contentWrapper.css('height', 'auto');
                }, 500);
            } else {
                contentWrapper.css('height', newHeight + 'px');

                setTimeout(function() {
                    contentWrapper.css('height', 0);
                }, 0);
            }
        });
    });
})();

// Smooth Scroll
(function(){
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - 120
          }, 1000);
        }
      }
    });
})();

// Sidebar menu
(function(){
    var menuSidebar = $('.sidebar-menu__submenu');
    var menuSidebarItem = menuSidebar.find('li.has-submenu');
    var menuSidebarItemSubmenu = menuSidebarItem.find('.sidebar-menu__submenu');
    menuSidebarItem.append( "<span class='arrow'></span>" );
    var menuSidebarArrow = menuSidebarItem.find('.arrow');
    menuSidebarItemSubmenu.wrap( '<div class="sub-menu-wrapper"></div>' );

    menuSidebarArrow.each(function(i, item) {
        $(item).parent().find('.sub-menu-wrapper').css('height', 0);

        if ($(item).parent().hasClass('open') || $(item).parent().find('.sidebar-menu__submenu li').hasClass('active')) {
            $(item).parent().find('.sub-menu-wrapper').css('height', 'auto');
        }

        $(item).on("click", function () {
            var item = $(this);
            var contentWrapper = item.parent().find('.sub-menu-wrapper');
            var contentText = item.parent().find('.sidebar-menu__submenu');

            item.parent().toggleClass('open');
            var newHeight = contentText.outerHeight();

            if (item.parent().hasClass('open')) {
                contentWrapper.css('height', 0);

                setTimeout(function() {
                    contentWrapper.css('height', newHeight + 'px');
                }, 0);

                setTimeout(function() {
                    contentWrapper.css('height', 'auto');
                }, 500);
            } else {
                contentWrapper.css('height', newHeight + 'px');

                setTimeout(function() {
                    contentWrapper.css('height', 0);
                }, 0);
            }
        });
    });
})();

// Open filters mobile view
(function(){
    var mobileViewport = window.matchMedia("screen and (max-width: 720px)");

    if(!mobileViewport) {
        return
    }

    var openFilterBtn = $('.list-sort .btn-filter');
    var sidebar = $('.sidebar');
    var closeSidebar = sidebar.find('.close');

    if(openFilterBtn) {
        openFilterBtn.on("click", function () {
            sidebar.addClass('is-active');

            setTimeout(function() {
                sidebar.addClass('is-showing');
            }, 250);
        });

        closeSidebar.on("click", function () {
            sidebar.removeClass('is-showing');

            setTimeout(function() {
                sidebar.removeClass('is-active');
            }, 250);
        });
    }
})();

// Change search input placeholder on mobile
(function(){
    var mobileViewport = window.matchMedia("screen and (max-width: 560px)");
    var searchInput = $('.section-page-header__search .menu-header__search__input');

    if (searchInput) {
        if (mobileViewport.matches) {
            searchInput.attr('placeholder', 'Search product name or type');
        }

        mobileViewport.addListener((mq) => {
            if (mq.matches) {
                searchInput.attr('placeholder', 'Search product name or type');
            } else {
                searchInput.attr('placeholder', 'What product name or type are you looking for?');
            }
        });
    }
})();